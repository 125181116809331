import React, { Component } from "react"

class CategorySelectResetter extends Component {
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(){
    var query = ''
    this.props.callback(query)
}
  render() {
      return (
        <div className='voice_list_btn'>
          <button id="close_btn" className="more_btn" onClick={this.handleClick}>表示数を戻す</button>
        </div>
    )
  }
}

export default CategorySelectResetter