import React, { Component } from "react"

import SelectOption from './SelectOption'

import SelectOption_place from './SelectOption_place'

import SelectOption_pay from './SelectOption_pay'

import { FormContext } from './FormContextProvider'

class FormBody extends Component {
  static contextType = FormContext

  render() {
    const { type, form_info, events, selected_id } = this.context
    const { step, select_list, form_inputs, form_validation_campaign, form_validation_free, handleChange, handleClick_campaign, handleClick_free } = this.props
    return (
      <div id="form_input_body" style={{display: step == '0' ? "block" : "none"}}>
          <SelectOption select_list={ select_list } 
            handleChange={ handleChange }
          />
        { type === 'campaign' &&
          <SelectOption_place select_list={ select_list } 
            handleChange={ handleChange }
          />
        }

        <div className="form-section">

        <p className="form-section-title">下記のフォームに必要事項を入力して「入力確認画面へ」をクリックしてください。</p>
        <div className="form-block">
          <div className="form-input-title">
            <p>氏名<strong>必須</strong></p>
          </div>
          <div className="form-input-item">
            <div className="input-column2-wrap">
              <div className="input-column-box">
                <input type="text" placeholder="姓" name="last_name" id="lastname__c" value={ form_inputs.lastname__c } onChange={ handleChange }/>
              </div>
              <div className="input-column-box">
                <input type="text" placeholder="名" name="first_name" id="firstname__c" value={ form_inputs.firstname__c } onChange={ handleChange }/>
              </div>
            </div>
            <p className="error-txt name-part" style={{display: form_validation_campaign.lastname__c || form_validation_campaign.firstname__c || form_validation_free.lastname__c || form_validation_free.firstname__c ? "block" : "none" }}>※氏名が入力されていません。</p>
          </div>
        </div>
        </div>

        <div className="form-block">
          <div className="form-input-title">
            <p>氏名（カナ）<strong>必須</strong></p>
          </div>
          <div className="form-input-item">
            <div className="input-column2-wrap">
              <div className="input-column-box">
                <input type="text" placeholder="セイ" name="00N5F000006xIaS" id="lastname_kana__c" value={ form_inputs.lastname_kana__c } onChange={ handleChange }/>
              </div>
              <div className="input-column-box">
                <input type="text" placeholder="メイ" name="00N5F000006xIaQ" id="firstname_kana__c" value={ form_inputs.firstname_kana__c } onChange={ handleChange }/>
              </div>
            </div>
            <p className="error-txt namekana-part" style={{display: form_validation_campaign.lastname_kana__c || form_validation_campaign.firstname_kana__c || form_validation_free.lastname_kana__c || form_validation_free.firstname_kana__c ? "block" : "none" }}>※氏名(カナ)が入力されていません。</p>
          </div>
        </div>

        <div className="form-block">
          <div className="form-input-title">
            <p>メールアドレス<strong>必須</strong></p>
          </div>
          <div className="form-input-item">
            <input type="text" placeholder="info@aaaaa.com" name="email" id="email" value={ form_inputs.email } onChange={ handleChange }/>
            <p className="error-txt email-part" style={{display: form_validation_campaign.email || form_validation_free.email ? "block" : "none" }}>※メールアドレスが入力されていません。</p>
          </div>
        </div>

        <div className="form-block">
          <div className="form-input-title">
            <p>メールアドレス(確認用)<strong>必須</strong></p>
          </div>
          <div className="form-input-item">
            <input type="text" placeholder="同じメールアドレスを入力してください" name="email_confirm" id="email_confirm" value={ form_inputs.email_confirm } onChange= { handleChange }/>
            <p className="error-txt emailconfirm-part" style={{display: form_validation_campaign.email_confirm || form_validation_free.email_confirm ? "block" : "none"}}>※メールアドレスが入力されていません。</p>
            <p className="error-txt emailconfirm-part2" style={{display: form_inputs.email === form_inputs.email_confirm ? "none" : "blcok"}}>※メールアドレスが異なります。</p>
          </div>
        </div>

        <div className="form-block">
          <div className="form-input-title">
            <p>電話番号<strong>必須</strong></p>
          </div>
          <div className="form-input-item">
            <input type="text" placeholder="0612345678" name="mobile" id="mobile_phone" value={ form_inputs.mobile_phone } onChange={ handleChange } />
            <p className="error-txt phone-part" style={{display: form_validation_campaign.mobile_phone || form_validation_free.mobile_phone ? "block" : "none" }}>※電話番号が入力されていません。</p>
          </div>
        </div>

        { type === 'campaign' &&
        <React.Fragment>
          <div className="form-block">
            <div className="form-input-title">
              <p>会社名<strong>必須</strong></p>
              <p class="caution">※現在就職・転職活動中の方はこちらの欄にその旨を記載ください。</p>
            </div>
            <div className="form-input-item">
              <input type="text" placeholder="株式会社〇〇" name="company" id="company" value={ form_inputs.company } onChange={ handleChange } />
              <p className="error-txt company-part" style={{display: form_validation_campaign.company ? "block" : "none" }}>※会社名が入力されていません。</p>
            </div>
          </div>

          <SelectOption_pay select_list={ select_list } 
            handleChange={ handleChange }
          />

          <div className="form-block form-address">
            <div className="form-input-title form-text">
              <p>テキスト郵送先住所<strong>必須</strong></p>
              <p class="caution">※テキストは一式郵送となりますので、ご勤務先もしくはご自宅いずれかご都合よろしい方でご入力ください。</p>
            </div>
            <div className="addressform">
              <div className="form-input-title">
                <p>郵便番号<strong>必須</strong></p>
              </div>
              <div className="form-input-item">
                <input type="text" placeholder="0000000" name="code" id="code" value={ form_inputs.code } onChange={ handleChange }/>
                <p className="error-txt code-part" style={{display: form_validation_campaign.code ? "block" : "none" }}>※郵便番号が入力されていません。</p>
              </div>
            </div>
            <div className="addressform">
              <div className="form-input-title">
                <p>都道府県<strong>必須</strong></p>
              </div>
              <div className="form-input-item">
                <input type="text" placeholder="東京都" name="prefectures" id="prefectures" value={ form_inputs.prefectures } onChange={ handleChange } />
                <p className="error-txt prefectures-part" style={{display: form_validation_campaign.prefectures ? "block" : "none" }}>※都道府県が入力されていません。</p>
              </div>
            </div>
            <div className="addressform">
              <div className="form-input-title">
                <p>市区郡町村<strong>必須</strong></p>
              </div>
              <div className="form-input-item">
                <input type="text" placeholder="千代田区" name="city" id="city" value={ form_inputs.city } onChange={ handleChange } />
                <p className="error-txt city-part" style={{display: form_validation_campaign.city ? "block" : "none" }}>※市区郡町村が入力されていません。</p>
              </div>
            </div>
            <div className="addressform">
              <div className="form-input-title">
                <p>番地・建物名<strong>必須</strong></p>
              </div>
              <div className="form-input-item">
                <input type="text" placeholder="〇〇番地　〇〇ビル101" name="address" id="address" value={ form_inputs.address } onChange={ handleChange } />
                <p className="error-txt address-part" style={{display: form_validation_campaign.address ? "block" : "none" }}>※番地・建物名が入力されていません。</p>
              </div>
            </div>
          </div>
          </React.Fragment>
        }
        <div className="form-block">
          <div className="form-input-title">
            <p>紹介者</p>
          </div>
          <div className="form-input-item">
            <input type="text" name="00N5F000006xIaU" id="recommender__c" value={ form_inputs.recommender__c } onChange={ handleChange } />
          </div>
        </div>

        <div className="form-block">
          <div className="form-input-title">
            <p>その他  お問い合わせ</p>
          </div>
          <div className="form-input-item">
            <textarea name="00N10000005pwXh" id="informations" value= { form_inputs.informations } onChange={ handleChange } />
          </div>
        </div>

        <div className="form-block">
          <div className="form-input-title">
            <p>受講目的<strong>必須</strong></p>
          </div>
          <div className="form-input-item purpose-radio">
            <label>
              <input type="radio" name="purpose__c" id="purpose__c" value="勤めている会社の業務に役立てるため" onChange={ handleChange }/>
              勤めている会社の業務に役立てるため
            </label>
            <label>
              <input type="radio" name="purpose__c" id="purpose__c" value="個人の事業に役立てるため" onChange={ handleChange }/>
              個人の事業に役立てるため
            </label>
            <label>
              <input type="radio" name="purpose__c" id="purpose__c" value="スキルアップし転職を考えている" onChange={ handleChange }/>
              スキルアップし転職を考えている
            </label>
            <label>
              <input type="radio" name="purpose__c" id="purpose__c" value="その他" onChange={ handleChange }/>
              その他
            </label>
            <input type="text" name="purpose__c" id="purpose__c" value={ form_inputs.purpose__c } onChange={ handleChange }/>
            <p className="error-txt purpose-part" style={{display: form_validation_campaign.purpose__c || form_validation_free.purpose__c ? "block" : "none" }}>※受講目的が入力されていません。 </p>
            <br/>
            <input type="hidden" id="00N5F000006xIaT" name="00N5F000006xIaT" value={ form_inputs.purpose__c } />
            <input type="hidden" id="00N5F000006xLCp" name="00N5F000006xLCp" value={ form_info.title } />
            <input type="hidden" id="00N5F000006xLCo" name="00N5F000006xLCo" value={ form_inputs['00N5F000006xLCo'] } />
          </div>
        </div>

        <div className="privacy-policy">
          <label>
            <input type="checkbox" name="privacy" id="privacy" value= { form_inputs.privacy } onChange={ handleChange }/>
              <a href="https://www2.zenoffice.co.jp/l/94582/2020-03-19/42h9py" className="zen_blank" target="_blank">個人情報保護方針とDMU受講規約</a>に同意する
          </label>
        </div>
        
        <p className="error-txt confirm-part" style={{display: form_validation_campaign.all || form_validation_free.all ? "block" : "none" }}>※必須項目にエラーがあります。赤字に従い修正してください。</p>
        <div className="btn-wrap">
        { type === 'campaign' &&
          <button className="btn-confirm zen_arrow-r" id="gotoconfirm-btn" onClick={ handleClick_campaign }>入力確認画面へ</button>
        }
        { type === 'free_seminar' &&
          <button className="btn-confirm zen_arrow-r" id="gotoconfirm-btn2" onClick={ handleClick_free }>入力確認画面へ</button>
        }
        </div>

      </div>
    )
  }
}

export default FormBody