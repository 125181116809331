import React, { Component } from "react"

class VoiceElement extends Component {

  render() {
      return (
        
      null
    )
  }
}

export default VoiceElement