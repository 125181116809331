import React, { Component } from "react"
import CategorySelectBox from "./CategorySelectBox";
import CategorySelectResetter from "./CategorySelectResetter";
import VoiceList from "./VoiceList"

class CategorySelector extends Component {
  constructor(props){
    super(props); 
    this.state = {
      url:'/voices/search',
      error: null,
      isLoaded: false,
      voices: []
    };
  }
  componentDidMount () {
    console.log('mount');
    this.fetch_url();
  }
  fetch_url(query){
    fetch(this.state.url + (query ? query:''))
    .then((response) => response.json())
    .then((responseJson) => {
      this.setState({
        isLoaded: true,
        voices:responseJson
      });
      console.log(responseJson);
    }).catch((error) =>{
  console.error(error);
  });
  }

  render() {
    
    const data = this.props.category;
    
      return (
        <div>
          <VoiceList data={this.state.voices}></VoiceList>
        </div>
        
    )
    
  }
}

export default CategorySelector