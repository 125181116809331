import React, { Component } from "react"

import { FormContext } from './FormContextProvider'

class ModalHeader extends Component {
  static contextType = FormContext

  render() {
    const { form_info } = this.context
    const { step } = this.props
    return(
      <div className="modal-header">
        <button type="button" name="button" className="close" data-dismiss="modal">&times;</button>
        <header className="form-contents-header">
          <p className="form-title">{ form_info.title }</p>
          <div className="form-step">
            <ol>
              { form_info.step.map((value, index, array) => {
                return <li key={ index } className={ index == step ? "current" : "" }>{ value }</li>
              })}
            </ol>
          </div>
        </header>
      </div>
    )
  }
}

export default ModalHeader




