import React, { Component } from "react"
import { FormContext } from './FormContextProvider'

class SelectOption_place extends Component {
  static contextType = FormContext

  render() {
    const { select_list, handleChange } = this.props
    const { selected_id, type } = this.context

    return (
      <div className="form-section">
        <div className="form-block form_pay">
          <div className="form-pay-select">
            <div className="form-input-title">
                <p>受講希望場所<strong>必須</strong></p>
            </div>
            <div className="form-input-item">
              {
  
                  <select name="00N5F000006xKe5" id="seleted_place" onChange={ handleChange }>
                    <option value={null} explain="選択してください">選択してください</option>
                    <option value="東京" explain="東京">東京</option>
                    <option value="オンライン" explain="オンライン">オンライン</option>
                  </select>
                
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SelectOption_place