import React, { Component, createContext} from 'react'

export const FormContext = createContext()

class FormContextProvider extends Component {
    constructor(props) {
        super(props)
        const { type, form_info, events, selected_id } = this.props
        this.state = {
            type, form_info, events, selected_id
        }
    }

    componentWillReceiveProps({ type, form_info, events, selected_id }) {
        this.setState({
            type, form_info, events, selected_id
        })
    }

    render() {
        const { children } = this.props
        return (
            <FormContext.Provider value={{...this.state}}>
                { children }
            </FormContext.Provider>
        )
    }
}

export default FormContextProvider