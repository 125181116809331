import React, { Component } from "react"

import { FormContext } from './FormContextProvider'

class FormConfirm extends Component {
    static contextType = FormContext
    render() {
        const { step, form_inputs, handleSubmit, handleBack } = this.props
        const { type } = this.context
        return (
            <div className="form-section" id="confirm-form-section" style={{display: step == '1' ? "block" : "none"}}>
                <p className="form-section-title">ご都合の良い日時を選択してください。</p>
                <div className="form-block">
                    <div className="form-input-title">
                        <p>参加希望日程</p>
                    </div>
                { type == 'free_seminar' &&
                    <div className="form-input-item">
                        { `${form_inputs.date}` }
                    </div>
                }
                { type == 'campaign' &&
                    <div className="form-input-item">
                        { `${form_inputs.seleted_value}` }
                    </div>
                }
                </div>
                { type == 'campaign' &&
                <div className="form-block">
                    <div className="form-input-title">
                        <p>受講希望場所</p>
                    </div>
                    <div className="form-input-item">
                        { `${form_inputs.seleted_place}` }
                    </div>
                </div>
                }
                <div className="form-section">
                    <p className="form-section-title">下記のフォームに必要事項を入力して「入力確認画面へ」をクリックしてください。</p>
                    <div className="form-block">
                        <div className="form-input-title">
                            <p>氏名</p>
                        </div>
                        <div className="form-input-item">
                            { `${form_inputs.lastname__c}  ${form_inputs.firstname__c}` }
                        </div>
                    </div>
                    <div className="form-block">
                        <div className="form-input-title">
                            <p>氏名（カナ）</p>
                        </div>
                        <div className="form-input-item">
                            { `${form_inputs.lastname_kana__c}  ${form_inputs.firstname_kana__c}` }
                        </div>
                    </div>
                    <div className="form-block">
                        <div className="form-input-title">
                            <p>メールアドレス</p>
                        </div>
                        <div className="form-input-item">
                            { `${form_inputs.email}` }
                        </div>
                    </div>

                    <div className="form-block">
                        <div className="form-input-title">
                            <p>電話番号</p>
                        </div>
                        <div className="form-input-item">
                            { `${form_inputs.mobile_phone}` }
                        </div>
                    </div>
                { type == 'campaign' &&
                <React.Fragment>
                    <div className="form-block">
                        <div className="form-input-title">
                            <p>会社名</p>
                        </div>
                        <div className="form-input-item">
                            { `${form_inputs.company}` }
                        </div>
                    </div>
                    
                    <div className="form-block">
                        <div className="form-input-title">
                            <p>お支払い方法</p>
                        </div>
                        <div className="form-input-item">
                            { `${form_inputs.seleted_pay}` }
                        </div>
                    </div>

                    <div className="form-block">
                        <div className="form-input-title">
                            <p>郵便番号</p>
                        </div>
                        <div className="form-input-item">
                            { `${form_inputs.code}` }
                        </div>
                    </div>

                    <div className="form-block">
                        <div className="form-input-title">
                            <p>都道府県</p>
                        </div>
                        <div className="form-input-item">
                            { `${form_inputs.prefectures}` }
                        </div>
                    </div>

                    <div className="form-block">
                        <div className="form-input-title">
                            <p>市区郡町村</p>
                        </div>
                        <div className="form-input-item">
                            { `${form_inputs.city}` }
                        </div>
                    </div>

                    <div className="form-block">
                        <div className="form-input-title">
                            <p>番地・建物名</p>
                        </div>
                        <div className="form-input-item">
                            { `${form_inputs.address}` }
                        </div>
                    </div>
                    </React.Fragment>
                    }
                    <div className="form-block">
                        <div className="form-input-title">
                            <p>紹介者</p>
                        </div>
                        <div className="form-input-item">
                            { `${form_inputs.recommender__c}` }
                        </div>
                    </div>

                    <div className="form-block">
                        <div className="form-input-title">
                            <p>その他  お問い合わせ</p>
                        </div>
                        <div className="form-input-item">
                            { `${form_inputs.informations}` }
                        </div>
                    </div>


                    <div className="form-block">
                        <div className="form-input-title">
                            <p>受講目的</p>
                        </div>
                        <div className="form-input-item">
                            { `${form_inputs.purpose__c}` }
                        </div>
                    </div>

                    <div className="btn-wrap">
                        <button className="btn-send zen_arrow-r complete-send-btn" onClick={ handleSubmit }>送信する</button>
                        <button className="btn-back zen_arrow-l changeForm-btn" onClick={ handleBack }>入力内容を修正</button>
                    </div>
                </div>
            </div>
        )
    }
}


export default FormConfirm


