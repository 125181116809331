import React, { Component } from "react"
import PropTypes from "prop-types"

import ModalHeader from './ModalHeader'
import ModalBody from './ModalBody'

import FormContextProvider from './FormContextProvider'

class FormModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: props.type,
            step: 0
        }
    }

    componentDidMount() {
        window.$('#myModal').on('hidden.bs.modal', () => {
            this.setState({
                type: null
            })
        })
    }
    componentWillReceiveProps({ type }) {
        this.setState({
            type,
            step: 0
        })
    }

    render() {
        const { events } = window.gon
        const form_info = this._getCorrectFormInfo()
        const { step, type } = this.state
        const { selected_id } = this.props
        if (type !== null) {
            return(
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <FormContextProvider
                            type={ type }
                            form_info={ form_info[type] }
                            events={ events[type] }
                            selected_id={ selected_id }
                        >
                            <ModalHeader 
                                step={ step }
                            />
                            <ModalBody 
                                step={ step }
                                forWard={ this._handleStepGo }
                                backward={ this._handleStepBack }
                            />
                        </FormContextProvider>
                    </div>
                </div>
            ) 
        } else {
            return false
        }
    }

    _getCorrectFormInfo = () => {
        const { form_info } = window.gon
        const url = location.href
        if (url.indexOf('/sub_top') > -1 ) {
            return form_info['sub']
        } else {
            return form_info['home']
        }
    }

    _handleStepBack = () => {
        const { step } = this.state
        this.setState({
            step: step - 1
        })
    }

    _handleStepGo = () => {
        const { step } = this.state
        this.setState({
            step: step + 1
        })
    }
}



export default FormModal