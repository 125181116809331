import React from "react"
import { VoiceElement } from './VoiceElement'

class VoiceList extends React.Component {
  render () {    
    return (
      <div id="number_list">
        <ul className="voice_list">
          { this.props.data.map(function (voice) {
            return(
              <li className="list_item voice">
                <a href={'/voices/'+voice.id} className="voice_btn">
                    < img src = {voice.image1__c} alt = {voice.person_name__c}  className = "voice_img" />
                  <div className='voice_title'>
                  <p className="voice_number">
                    Voice.{voice.name}
                  </p>
                  <h3 className="voice_name">
                    <span className="company_name">{voice.occupation__c}</span>{voice.person_name__c} 様 
                  </h3>
                  </div>
                </a>
                <p className="voice_text">
                    {voice.title__c}
                  </p>
              </li>
            )
            }
          ) }
          </ul>
        </div>
    );
  }
}

export default VoiceList
