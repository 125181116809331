import React, { Component } from "react"
import { FormContext } from './FormContextProvider'

class SelectOption extends Component {
  static contextType = FormContext

  render() {
    const { select_list, handleChange } = this.props
    const { selected_id, type } = this.context

    return (
      <div className="form-section">
      { type === "campaign" &&
        <p className="form-section-title">ご希望のコースを選択してください。</p>
        }
      { type === "free_seminar" &&
        <p className="form-section-title">ご都合の良い日時を選択してください。</p>
        }
        <div className="form-block">
          <div className="form-input-title">
              <p>参加希望コース<strong>必須</strong></p>
          </div>
          <div className="form-input-item">
            {
              selected_id ? 
                this._makeOptions()
               : (
                <select name="00N5F000006xKe4" id="seleted_value" onChange={ handleChange }>
                  <option value={null} explain="選択してください">選択してください</option>
                  { this._makeOptions() }
                </select>
              )
            }
            { type === "campaign" &&
              <p class="caution">※オンライン以外は別途入学金25,000円が必要ですが、お客様事例にご協力いただけましたら免除させていただきます。</p>
            }
          </div>
        </div>
      </div>
    )
  }

  _makeOptions = () => {
    const { select_list } = this.props
    const { selected_id } = this.context
    let options

    if (selected_id) {
      options = select_list.filter((event) => {
        return event.value == selected_id
      })

      options = options.map((event, index, array) => {
          return (
            <option
              key={ index }
              value={ event.value } 
              explain={ event.explain } 
              time={ event.time }>
              { event.explain }
            </option>
          )
      })
    } else {
      options = select_list.map((event, index, array) => {
        return (
          <option 
            key={ index } 
            value={ event.value } 
            explain={ event.explain } 
            time={ event.time }>
            { event.explain }
          </option>
        )
      })
    }

    return options
  }
}

export default SelectOption