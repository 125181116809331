import React, { Component } from "react"
import { callbackify } from "util";

class CategorySelectBox extends Component {
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e){
    var category = e.currentTarget.getAttribute('data-category');
    var name = e.currentTarget.getAttribute('data-name');
    console.log(category);
    console.log(name);
    var query = '?'+'category='+category+'__c&'+'name='+name
    this.props.callback(query)
}
  render() {
    const data = this.props.data;
      return (
        <li className="small_element" data-name={data.name} data-category={data.category} onClick={this.handleClick}>
          <p className="small_element_btn" >{data.name}</p>
        </li>
    )
  }
}

export default CategorySelectBox