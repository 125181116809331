import React, { Component } from "react"
import PropTypes from "prop-types"
import FormBody from './FormBody'
import FormConfirm from './FormConfirm'

import { FormContext } from './FormContextProvider'
class ModalBody extends Component {
  static contextType = FormContext

  constructor(props, context) {
    super(props, context)
    
    this.state = {
      select_list: this._makeOptions(),
      form_inputs: {
        seleted_value: '',                        // select value
        date: '',                                 // select explain
        seleted_place: '',                        // place
        freeclass_date: '',                       // select time
        lastname__c: '',                          // last_name
        firstname__c: '',                         // first_name
        lastname_kana__c: '',                     // lastname_kana__c
        firstname_kana__c: '',                    // firstname_kana__c
        email: '',                                // email
        email_confirm: '',                        // email_confirm
        mobile_phone: '',                         // mobile_phone
        seleted_pay: '',                          // pay
        company: '',                              // company
        code: '',                                 // code
        prefectures: '',                          // prefectures
        city: '',                                 // city
        address: '',                              // address
        recommender__c: '',                       // recommender__c
        informations: '',                         // informations
        purpose__c: '',                           // purpose__c
        '00N5F000006xLCo': this._convertDate(),   // date
        privacy: false,                           // privacy
      },
      form_validation_campaign: {
        seleted_value: false, seleted_pay: false, seleted_place: false, lastname__c: false, firstname__c: false,
        lastname_kana__c: false, firstname_kana__c: false, date: false,
        email: false, email_confirm: false, mobile_phone: false,
        company: false, code: false, prefectures: false, city: false, 
        address: false, purpose__c: false, privacy: false, all: false,
      },
      form_validation_free: {
        lastname__c: false, firstname__c: false,
        lastname_kana__c: false, firstname_kana__c: false, date: false,
        email: false, email_confirm: false, mobile_phone: false,
        purpose__c: false, privacy: false, all: false,
      },
      send_pardot: true,
      url_params: this._getParamsInSessionStorage()
    }
  }

  componentWillMount = () => {
    const prev_state = this.state
    const { selected_id } = this.context
    this.setState({
      ['form_inputs']: {
        ...prev_state.form_inputs,
        [`seleted_value`]: selected_id,
        [`date`]: selected_id ? this._getExplainSelectedDate(selected_id): ''
      }
    })
  }

  render() {
    const { form_info } = this.context
    const {
      select_list,
      form_inputs,
      form_validation_campaign,
      form_validation_free,
      url_params
    } = this.state
    const { step } = this.props
    const hidden_params = []
    const domainURL = window.location.hostname
    for (var i in url_params){
      hidden_params.push(<input type = "hidden" name = {i} value = {url_params[i]} />)
    }
    return(
      <div className="modal-body">
        <main id="main">
          <article className="form-contents">
            <div className="form-body">
              <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" id="web-to-lead-from">
                <input type="hidden" name="oid" value="00D10000000bPGB" />
                <input type="hidden" name="retURL" value={`${ domainURL }/${ form_info.retURL }`} />
                { hidden_params }
                  <FormBody
                    step={ step }
                    select_list={ select_list }
                    form_inputs={ form_inputs }
                    form_validation_campaign={ form_validation_campaign }
                    form_validation_free={ form_validation_free }
                    handleChange={ this._handleChange }
                    handleClick_campaign={ this._handleButtonClickCampaign }
                    handleClick_free={ this._handleButtonClickFree }
                  />
                  <FormConfirm
                    step={ step }
                    form_inputs={ form_inputs }
                    handleSubmit={ this._handleSubmitClick }
                    handleBack={ this._handleCancelClick }
                  />
              </form>
            </div>
          </article>
        </main>
      </div>
    )
  }

  _handleCancelClick = (e) => {
    e.preventDefault()
    const { backward } = this.props

    backward()
  }

  _handleButtonClickCampaign = (e) => {
    e.preventDefault()
    const { forWard } = this.props
    this._formValidation_campaign()
    const { form_validation_campaign } = this.state

    if (!form_validation_campaign.all) {
        forWard()
    }
  }

  _handleButtonClickFree = (e) => {
    e.preventDefault()
    const { forWard } = this.props
    this._formValidation_free()
    const { form_validation_free } = this.state

    if (!form_validation_free.all) {
        forWard()
    }
  }

  _handleSubmitClick = (e) => {
    let { send_pardot } = this.state
    if (send_pardot) {
      e.preventDefault()
      this.setState({
        send_pardot: false
      })
      this._sendToPardot()
    }
  }

  _sendToPardot = () => {
    const { pardot_url } = this.context.form_info
    if (!pardot_url) return

    const url = pardot_url + this._keyToParmas()
    
    let $pardot = window.$('<iframe id="pardot" height="0" width="0" style="position:absolute; left:-100000px; top:-100000px"></iframe>')
    window.$('body').append($pardot)
    window.$(this).css('cursor','pointer')
    $pardot.attr('src', url)
    $pardot.on('load', () => {
      console.log('url', url)
      window.$('.complete-send-btn').click()
    })
  }

  _keyToParmas = () => {
    const { form_inputs } = this.state
    let params = '?'
    for (let key in form_inputs) {
      params += `${key}=${form_inputs[key]}&`
    }
    const { url_params } = this.state
    if(url_params){
      for (let key in url_params) {
        params += `${key}=${url_params[key]}&`
      }
    }
    return params
  }

  _formValidation_campaign = () => {
    const { form_inputs, form_validation_campaign } = this.state

    for (let key in form_validation_campaign) {
      for (let form_id in form_inputs) {
        if (form_id === key) {
          if (!form_inputs[key]){
            form_validation_campaign[key] = true
          } else {
            form_validation_campaign[key] = false
          }
        }
      }
    }

    let count = 0
    for (let key in form_validation_campaign) {
      if (key !== 'all' && form_validation_campaign[key]) {
        count += 1
      }
    }

    if (count > 0) {
      form_validation_campaign['all'] = true
    }else {
      form_validation_campaign['all'] = false
    }

    this.setState({
      form_validation_campaign
    })
  }

  _formValidation_free = () => {
    const { form_inputs, form_validation_free } = this.state

    for (let key in form_validation_free) {
      for (let form_id in form_inputs) {
        if (form_id === key) {
          if (!form_inputs[key]){
            form_validation_free[key] = true
          } else {
            form_validation_free[key] = false
          }
        }
      }
    }

    let count = 0
    for (let key in form_validation_free) {
      if (key !== 'all' && form_validation_free[key]) {
        count += 1
      }
    }

    if (count > 0) {
      form_validation_free['all'] = true
    }else {
      form_validation_free['all'] = false
    }

    this.setState({
      form_validation_free
    })
  }

  _handleChange = (e) => {
    const prev_state = this.state
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.id

    const select = target.tagName === 'SELECT'
    const option = select ? target.selectedOptions[0] : ''
    const selected_explain = option ? option.getAttribute('explain') : ''
    const selected_time = option ? option.getAttribute('time') : ''

    if (!select) {
      this.setState({
        ['form_inputs']: {
          ...prev_state.form_inputs,
          [name]: value
        }
      })
    } else {
      this.setState({
        ['form_inputs']: {
          ...prev_state.form_inputs,
          [name]: value,
          date: selected_explain,
          freeclass_date: selected_time
        }
      })
    }
  }

  _getExplainSelectedDate = (selected_id) => {
    const { events } = this.context
    event = events.filter((sel) => {
      return sel.sf_id  === selected_id
    })

    return `${event[0]['month__c']}/${event[0]['day__c']} (${event[0]['day_of_week__c']})  ${event[0]['start_time__c']}~${event[0]['end_time__c']}  ${event[0]['title']}`
  }
  
  _makeOptions = () => {
    const { type, events } = this.context
    let options = ''
    if (type == 'free_seminar') {
       options = events.map((event, index, array) => {
        const date = `${event['year']}-${event['month__c']}-${event['day__c']}`
        const explain = `${event['month__c']}/${event['day__c']} (${event['day_of_week__c']})  ${event['start_time__c']}~${event['end_time__c']}  ${event['title']}`
        return {value: event['sf_id'], explain: explain, time: date}
      })
    } else if (type == 'campaign') {
      options = events.map((event, index, array) => {
        event.time = this._convertDate()
        return event
      })
    }

    return options
  }

  _convertDate = () => {
      var d = new Date()
      var convertdate = d.getFullYear() + '-' + ('0' + (d.getMonth()+1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2) +'T' + ('0' + d.getHours()).slice(-2)+':'+('0' + d.getMinutes()).slice(-2)+':'+d.getSeconds()+'0'
      return convertdate
  }

  _getParamsInSessionStorage = () => {
    var length = sessionStorage.length
    var params = {}
    for (var i = 0; i < length; i++) {
      var key = sessionStorage.key(i)
      params[key] = sessionStorage.getItem(key)
    }
    return params
  }
}


export default ModalBody
