import React, { Component } from "react"
import { FormContext } from './FormContextProvider'

class SelectOption_pay extends Component {
  static contextType = FormContext

  render() {
    const { select_list, handleChange } = this.props
    const { selected_id, type } = this.context

    return (
      <div className="form-section">
        <div className="form-block form_pay">
          <div className="form-pay-select">
            <div className="form-input-title">
                <p>お支払い方法<strong>必須</strong></p>
            </div>
            <div className="form-input-item">
              {
    
                  <select name="00N5F000006xKe6" id="seleted_pay" onChange={ handleChange }>
                    <option value={null} explain="選択してください">選択してください</option>
                    <option value="クレジットカード払い（一括・分割）" explain="クレジットカード払い（一括・分割）">クレジットカード払い（一括・分割）</option>
                    <option value="銀行振り込み" explain="銀行振り込み">銀行振り込み</option>
                    <option value="請求書払い（法人様のみ可）" explain="請求書払い（法人様のみ可）">請求書払い（法人様のみ可）</option>
                  </select>
     
              }
            </div>
          </div>
          <div className="pay_caution">
            <p>※クレジットカード払いは「Paypal」をご利用いただいております。（VISAやJCB、Mastercard、AMEXなどPaypalにて利用可能です。）</p> 
            <p>※クレジットカードの分割については、ご契約のカード会社によって異なりますので、カード会社にお問い合わせください。</p>
            <p>※請求書払いは法人様のみ可能です。申込月の月末に請求書発行、翌月末お支払いのスケジュールとなります。</p>
          </div>
        </div>
      </div>
    )
  }
}

export default SelectOption_pay